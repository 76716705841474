import { IconButton, Typography } from "@mui/material";
import HeaderText from "../../components/header/headerText";
import { colors } from "../../constants/colors";
import ButtonCustom from "../../components/button/buttonCustom";
import { ArrowBackIos, ArrowForwardIos, Download } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { numberFormat } from "../../utils/common";
import { swalError } from "../../components/notification/swal";
// import PaymentUploadApi from "../../api/payment/payment-upload.api";
import PaymentApi from '../../api/payment/payment.api'
import Loading from "../../components/loading/loading";
import liff from "@line/liff";

interface payHistory {
    onClickBack?: () => void;
}

export default function PayHistory(props: payHistory) {

    const [history, setHistory] = useState<any>([])
    const [page, setPage] = useState<number>(1)
    const [size, setSize] = useState<number>(10)
    const [totalPage, setTotalPage] = useState<number>(0)
    const [totalItem, setTotalItem] = useState<number>(0)
    const [loadingPage, setLoadingPage] = useState(false);

    const loadReceiptHistory = useCallback(() => {
        try {
            PaymentApi.getReceiptHistory({ page: page, size: size }).then((res: any) => {
                if (res.status === 200) {
                    const val = res.data?.data;

                    setHistory(val?.transactions || [])
                    setTotalPage(val?.total_page || 0)
                    setTotalItem(val?.total_item || 0)

                }
            })
        } catch (error) {
            swalError('receipt history payment ' + error);
        }
    }, [page, size])
    // const loadReceiptPDF = async (receipt_no: string) => {
    //     try {
    //         setLoadingPage(true);
    //         const result = await PaymentApi.getReceiptPDF({ receiptNo: receipt_no });
    //         if (res.status == 200) {
    //             liff.openWindow(
    //                 {
    //                     url: res.data.data,
    //                     external: true
    //                 }
    //             )
    //         }
    //         setLoadingPage(false);
    //     } catch (error) {
    //         setLoadingPage(false);
    //         console.error('Error downloading file:', error);
    //     }
    // };

    const loadReceiptPDF = async (receipt_no: string) => {
        setLoadingPage(true)

        try {
            const result = await PaymentApi.getReceiptPDF({ receiptNo: receipt_no });

            if (result.status === 200) {
                const url = String(result.data?.data?.file);
                if (url) {
                    liff.openWindow({
                        url: url,
                        external: true
                    });
                } else {
                    swalError('File URL is not available.');
                }
            }
        } catch (error) {
            swalError('receipt no payment ' + error);
        } finally {
            setLoadingPage(false);
        }
    }


    useEffect(() => {
        loadReceiptHistory();
    }, [page, size, loadReceiptHistory]);

    const handleColor = (status: string) => {
        switch (status) {
            case 'รอตรวจสอบ':
                return colors.warning;
            case 'ยกเลิก':
                return colors.danger;
            case 'สำเร็จ':
                return colors.success;
            default:
                return colors.black;
        }
    }

    const handleNextPage = () => {
        if (page < totalPage) {
            setPage(page + 1)
        }
    }

    const handlePreviosPage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    return (
        <div className="container pt-3 px-3 py-2">
            <Loading show={loadingPage} />
            <div className="pb-3">
                <div className="d-flex justify-content-between py-3 pe-4 position-fixed top-0" style={{ backgroundColor: colors.white, width: '100%', zIndex: 100 }}>
                    <HeaderText label="ประวัติการชำระ" enableBorder={true} />
                    <ButtonCustom
                        mode={'default'}
                        variant={'outlined'}
                        textButton={'ย้อนกลับ'}
                        active
                        onClick={() => {
                            if (props.onClickBack) {
                                props.onClickBack()
                            }
                        }
                        }
                    />
                </div>
                <div className="pt-5 mt-2 pb-5">
                    {history.map((item: any) => {
                        return (
                            <div className="pay-history-card">
                                <div className="d-flex flex-row justify-content-between">
                                    <div>
                                        <Typography variant="body2" className="pt-2 pb-1">
                                            {`วันที่ชำระ: ${item?.transaction_date}`}
                                        </Typography>
                                        <div className="d-flex flex-row">
                                            <Typography variant="body2" className="py-1 me-2">
                                                {`สถานะ:`}
                                            </Typography>
                                            <Typography variant="body2" className="py-1" color={handleColor(item?.status)}>
                                                {item?.status}
                                            </Typography>
                                        </div>
                                        <Typography variant="body2" className="py-1 fw-bold" color={colors.themeMainColor}>
                                            {`${numberFormat(item?.total_amount, 0, 0)} บาท`}
                                        </Typography>
                                    </div>
                                    {/* <div className="ms-auto py-4">
                                        <IconButton
                                            style={{
                                                backgroundColor: colors.themeMainColor,
                                                borderRadius: 8,
                                                border: '1px solid #E5E5E5'
                                            }}
                                            onClick={() => {
                                                loadReceiptPDF(item.receipt_no); // จากนั้นเรียก loadReceiptPDF พร้อมพารามิเตอร์
                                            }}
                                        >
                                            <Download style={{ color: colors.white, cursor: 'pointer' }} />
                                        </IconButton>
                                    </div> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
                {history?.length > 0 && <div style={{ position: 'fixed', bottom: 0, left: '50%', transform: 'translateX(-50%)', backgroundColor: colors.white, width: '100%' }}>
                    <div className="d-flex justify-content-between align-items-center py-2 px-2" >
                        <IconButton onClick={() => handlePreviosPage()} disabled={page === 1}>
                            <ArrowBackIos style={{ fill: page === 1 ? colors.disabledLightGray : colors.themeMainColor, cursor: 'pointer' }} />
                        </IconButton>
                        <Typography variant="body1" className="px-2" color={colors.themeMainColor}>{`${page}/${totalPage}`}</Typography>
                        <IconButton onClick={() => handleNextPage()} disabled={page === totalPage}>
                            <ArrowForwardIos style={{ fill: page === totalPage ? colors.disabledLightGray : colors.themeMainColor, cursor: 'pointer' }} />
                        </IconButton>
                    </div>
                </div>}
            </div>
        </div>
    )
}