import liff from "@line/liff";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AomdownApi from "../../api/aomdown/aomdown.api";
import AuthenApi from "../../api/authen/authen.api";
import Loading from "../../components/loading/loading";
import { swalError, swalWarning } from "../../components/notification/swal";
import OtpComponant from "../../components/otp/otp";
import { routeName } from "../../routes/routers-name";
import { setClientKey, setRefreshToken, setToken } from "../../utils/app.utils";
import { encryptString } from "../../utils/crypto-js";

export default function Login() {
    const [loadingPage, setLoadingPage] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [confirmOtp, setConfirmOtp] = useState<boolean>(false);

    const [accTokenLine, setAccTokenLine] = useState<any>('');
    const history = useHistory();
    const [errorOtp, setErrorOtp] = useState<string>('');

    useEffect(() => {
        // liff.init({ liffId: '2006354132-JmgxvxPR', withLoginOnExternalBrowser: true })
        liff.init({ liffId: `${process.env.REACT_APP_LIFF_ID_LOGIN}`, withLoginOnExternalBrowser: true })
            .then(async () => {
                if (liff.isLoggedIn()) {
                    const token = await liff.getAccessToken()
                    setAccTokenLine(token)
                } else {
                    liff.login();
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    const backStep = async () => {
        setPhoneNumber('');
        setLoadingPage(false);
        setConfirmOtp(false);
    }

    const login = async (numberOtp: string) => {
        try {
            setErrorOtp('')
            setLoadingPage(true);
            const encryptPhoneNumber = encryptString(phoneNumber);
            const encryptOtp = encryptString(numberOtp);
            const payload = {
                phoneNumber: encryptPhoneNumber,
                otp: encryptOtp,
                accessTokenLine: accTokenLine,
            };
            const res = await AuthenApi.login(payload);
            if (res.status === 200) {
                if (res.data.data.userLogin.message === "OTP_OVER_VERIFY") {
                    swalWarning(``, 'คุณใส่รหัส OTP ผิดเกิน 5 ครั้ง ระบบจะพาคุณกลับไปหน้ากรอกเบอร์โทรศัพท์', backStep);
                    return false
                }
                if (res.data.data.userLogin.message === "REGISTER") {
                    swalError(`ไม่พบข้อมูล กรุณาลงทะเบียนก่อน เข้าสู่ระบบ`, backStep);
                    return false
                }
                if (res.data.data.userLogin.message === "OTP_NOT_FOUND") {
                    setErrorOtp('OTP ไม่ถูกต้อง')
                    setLoadingPage(false);
                    return false
                }
                setToken(res.data.data.userLogin.accessToken || '');
                setRefreshToken(res.data.data.userLogin.refreshToken || '');
                setClientKey(res.data.data.userLogin.clientKey || '')
                const regisByAdmin = await checkAdminRegister(res.data.data.userLogin.accessToken, res.data.data.userLogin.clientKey)
                if (regisByAdmin) {
                    setLoadingPage(false);
                    return history.push(routeName.registerAomDown, { state: true })
                } else {
                    setLoadingPage(false);
                    await AuthenApi.verify(accTokenLine)
                    liff.closeWindow();
                }
                setLoadingPage(false);
            }
            setLoadingPage(false);
        } catch (error: any) {
            setLoadingPage(false);
            swalError(error.response.data.message, backStep);
        }
    };

    const checkAdminRegister = async (token: string, key: string) => {
        try {
            const res = await AomdownApi.getAdminRegisterNewToken(token, key);
            if (res.status === 200) {
                const data = res.data.data;
                if (data) {
                    return true
                } else {
                    return false
                }
            }
        } catch (error: any) {
            swalError(String('เกิดข้อผิดพลาดไม่สามารถทำรายการได้'));
            return false
        }
    };


    return (
        <div className="px-3 py-3">
            <div>
                <Loading show={loadingPage}></Loading>
                <OtpComponant
                    register={false}
                    phoneNumber={(data: any) => setPhoneNumber(data)}
                    confirmOtp={confirmOtp}
                    errorOtp={errorOtp}
                    verify={(data: any) => login(data)}
                    verifyConfirmOtp={(data: any) => setConfirmOtp(data)}
                />
            </div>
        </div>
    )
}