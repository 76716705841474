import { useEffect, useState } from "react";
import { getToken } from "../../utils/app.utils";
import { decryptString } from "../../utils/crypto-js";
import AuthenApi from "../../api/authen/authen.api";
import HeaderText from "../../components/header/headerText";
import InputTextField from "../../components/input/inputTextField";
import { phoneNumberFormat } from "../../utils/common";
import Person2Icon from '@mui/icons-material/Person2';
import { colors } from "../../constants/colors";

export default function PersonalInfomation() {
    const token = getToken()
    const [tel, setTel] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [fullName, setFullName] = useState<string>("");
    const loadCurrentUser = async () => {
        const result = await AuthenApi.getUser();
        if (result.status === 200 && result?.data?.data) {
            const data = result.data?.data?.getCurrent
            const decryptTel = decryptString(data?.tel);
            setTel(decryptTel); // tel
            setCode(data.code)
            setFullName(data.fullName)
        }
    }
    useEffect(() => {
        if (token) {
            loadCurrentUser();
        }
    }, [token]);

    return (
        <div className="px-3 py-3">
            <div className="mb-4">
                <HeaderText label="ข้อมูลส่วนตัว" enableBorder={true} />
            </div>
            <div className="text-center pb-2">
                <Person2Icon
                    sx={{
                        width: 120,
                        height: 120,
                        background: colors.themeSecondColor,
                        padding: 3,
                        borderRadius: '50%',
                        color: colors.white
                    }}
                />
            </div>
            <div className="pt-2">
                <InputTextField
                    value={fullName}
                    size="medium"
                    disabled
                    heading="ชื่อ-นามสกุล"
                />
            </div>
            <div className="pt-2">
                <InputTextField
                    value={code}
                    size="medium"
                    disabled
                    heading="เลขบัตรประชาชน"
                />
            </div>
            <div className="pt-2">
                <InputTextField
                    value={phoneNumberFormat(tel)}
                    size="medium"
                    disabled
                    heading="เบอร์โทรศัพท์"
                />
            </div>
            <div className="text-center pt-2" style={{ color: colors.red }}>
                หากต้องการแก้ไขข้อมูล โปรดติดต่อแอดมิน
            </div>
        </div>

    )
}