import { styled, Typography } from "@mui/material";
import { colors } from "../../constants/colors";

const HeaderTextStyle = styled(`div`)<{ color?: string }>(
    ({ color }) => ({
        borderLeft: `5px solid ${color}`,
        paddingLeft: 10,
        position: 'relative',
        display: 'inline-block',
    })
);

interface Props {
    label: string,
    color?: string,
    component?: React.ReactNode,
    enableBorder?: boolean
}

export default function HeaderText({ label, color, component, enableBorder }: Props) {
    const content = (
        <Typography variant="h6">
            {label}
            {component && <span style={{ paddingLeft: 10 }}>{component}</span>}
        </Typography>
    );

    return enableBorder ? (
        <HeaderTextStyle color={color || colors.themeMainColor}>
            {content}
        </HeaderTextStyle>
    ) : (
        content
    );
}