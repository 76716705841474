interface Option {
    color?: string
    backgroundColor?: string,
    className?: string
}

export default function PhoneIcon(props: Option) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1047_2646)">
                <rect width="32" height="32" rx="16" fill={props.backgroundColor || '#F7C77C'} />
                <g clip-path="url(#clip1_1047_2646)">
                    <path d="M25.7638 9.46806L17.9642 4.96495C17.5209 4.70903 16.9941 4.63968 16.4997 4.77215C16.0053 4.90463 15.5838 5.22808 15.3279 5.67135L6.96495 20.1563C6.70903 20.5996 6.63967 21.1264 6.77215 21.6208C6.90462 22.1152 7.22807 22.5367 7.67134 22.7926L15.471 27.2957C15.9142 27.5517 16.441 27.621 16.9354 27.4885C17.4298 27.3561 17.8513 27.0326 18.1073 26.5894L26.4702 12.1044C26.7261 11.6611 26.7954 11.1343 26.663 10.6399C26.5305 10.1455 26.207 9.72398 25.7638 9.46806ZM12.2144 23.93C12.0684 23.8455 11.9404 23.7331 11.8377 23.5992C11.7351 23.4652 11.6599 23.3124 11.6163 23.1494C11.5283 22.8202 11.5747 22.4695 11.7453 22.1745C11.8298 22.0284 11.9422 21.9004 12.0761 21.7978C12.2101 21.6951 12.3629 21.6199 12.5259 21.5763C12.8551 21.4883 13.2058 21.5347 13.5008 21.7053C13.7958 21.8759 14.011 22.1567 14.099 22.4859C14.1869 22.8151 14.1405 23.1658 13.97 23.4608C13.7994 23.7558 13.5186 23.971 13.1894 24.059C12.8601 24.147 12.5095 24.1005 12.2144 23.93Z" fill={props.color || "#FFF5E6"} />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1047_2646">
                    <rect width="32" height="32" rx="16" fill="white" />
                </clipPath>
                <clipPath id="clip1_1047_2646">
                    <rect width="12.866" height="20.5856" fill="white" transform="translate(16.2928 4) rotate(30)" />
                </clipPath>
            </defs>
        </svg>
    )
}