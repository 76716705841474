import axios from "axios";
import { getClientKey, getToken, setClientKey, setRefreshToken, setToken } from "../utils/app.utils";
import _ from "lodash";
import AuthenApi from "./authen/authen.api";

export const apiUrl = process.env.REACT_APP_API;
export const key = process.env.REACT_APP_X_API_KEY
export const clientKey = getClientKey() || process.env.REACT_APP_CLIENT_KEY;
const pathAuthen = 'authen/graphql'
const token = getToken();

const instance = axios.create({
  baseURL: apiUrl || process.env.REACT_APP_API,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": key,
    "client-key": clientKey,
    Authorization: token ? `Bearer ${token}` : "",
  },
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log(error);
  }
);

instance.interceptors.response.use(
  async (response: any) => {
    const graphqlErrors = response?.data?.errors;
    if (graphqlErrors) {
      const code = graphqlErrors[0]?.code;
      if (code === 'AUTH_TOKEN_EXPIRED') {
        return handleGetRefreshToken(response);
      }
      if (['AUTH_REFRESH_TOKEN_EXPIRED', 'AUTH_SESSION_EXPIRED'].includes(code)) {
        return handleRefreshTokenExpiration();
      }
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error?.response && error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;

      /* Call your refresh token function here */
      const refreshedToken = await refreshToken();

      const dtRefreshToken = refreshedToken?.data?.data;
      const errRefreshToken = _.head(refreshedToken?.data?.errors);

      /* Update the access token and retry the original request */
      if (dtRefreshToken) {
        await setAccessToken(dtRefreshToken?.userGetRefreshToken);
        return instance(originalRequest);
      } else {
        switch (errRefreshToken) {
          case 401:
            await handleRefreshTokenExpiration();
            break;
          default:
            await handleRefreshTokenExpiration();
            break;
        }
      }
    }
    return Promise.reject(error);
  },
);

const setAccessToken = (dt: any) => {
  setToken(dt?.accessToken)
  setClientKey(dt?.refreshToken)
  setRefreshToken(dt?.clientKey)
};

const handleRefreshTokenExpiration = async () => {
  await axios.post(
    `${apiUrl}/${pathAuthen}`,
    {
      query: `
        mutation revokeUserCustomerSessionByKey{
         revokeUserCustomerSessionByKey
        }
      `,
    },
    {
      headers: {
        Authorization: token,
        'x-api-key': key,
        'client-key': clientKey,
      },
    },
  );
  localStorage.clear();
};

const refreshToken = async () => {
  try {

    const response = await axios.post(
      `${apiUrl}/${pathAuthen}`,
      {
        query: `
          mutation UserGetRefreshToken($input: AuthenCustomerRefreshTokenInput!) {
            userGetRefreshToken(input: $input) {
              accessToken
              refreshToken
              clientKey
            }
          }
        `,
        variables: { input: { refreshToken } },
      },
      {
        headers: {
          Authorization: token,
          'x-api-key': key,
          'client-key': clientKey,
        },
      },
    );

    return response;
  } catch (error) {
    return null;
  }
};

const handleGetRefreshToken = async (response: any) => {
  const originalRequest = response.config;
  if (!originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const refreshedToken = await refreshToken();
      const dtRefreshToken = refreshedToken?.data?.data;
      const errRefreshToken = _.head(refreshedToken?.data?.errors);

      if (dtRefreshToken) {
        await setAccessToken(dtRefreshToken.userGetRefreshToken);
        originalRequest.headers.Authorization = `Bearer ${dtRefreshToken.userGetRefreshToken.accessToken}`;
        return axios.request(originalRequest);
      } else if (errRefreshToken) {
        return handleRefreshTokenExpiration();
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
};

export default class BaseAPI {
  static api = instance;
}
