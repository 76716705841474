import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';
import _ from 'lodash';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import HelperText from '../input/helperText';
import { colors } from '../../constants/colors';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type UploadImageProps = {
  onUpload?: (event: any) => void;
  helperText?: any;
  loading?: boolean | false;
  ref?: any;
  preview?: any;
  imageIconSvg?: any;
}

export default function UploadSlips(props: UploadImageProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      {_.isEmpty(props.preview) && props.loading === false && (
        <>
          <LoadingButton
            component="label"
            role={undefined}
            variant="text"
            tabIndex={-1}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {props.imageIconSvg ? (
                  <props.imageIconSvg />
                ) : (
                  <CloudUploadOutlinedIcon
                    sx={{
                      width: 100,
                      height: 100,
                      background: colors.lightGray,
                      padding: 3,
                      borderRadius: '50%',
                    }}
                  />
                )}
              </div>
              <div style={{ marginTop: 8, color: colors.black }}>
                อัพโหลดสลิปการชำระเงิน
              </div>
              {props.helperText && (
                <div className='pt-2 d-flex justify-content-center' style={{ color: colors.disabledGray }}>
                  {props.helperText}
                </div>
              )}
            </div>
            <VisuallyHiddenInput
              ref={props.ref}
              onClick={(event) => {
                (event.target as HTMLInputElement).value = '';
              }}
              type="file"
              accept="image/png, image/jpeg , image/jpg"
              onChange={(event: any) => { props.onUpload && props.onUpload(event) }}
            />
          </LoadingButton>
        </>
      )}
      {props.loading === true && (
        <LinearProgress sx={{ color: colors.themeMainColor }} />
      )}
      {!_.isEmpty(props.preview) && (
        <div>
          <div className='pb-2' style={{ borderRadius: '10%', display: 'flex', justifyContent: 'center' }}>
            <img src={`${props.preview}`} width="250" height="250" style={{ borderRadius: '10px' }} />
          </div>
          <div className='mt-auto'>
            <LoadingButton
              component="label"
              role={undefined}
              variant="text"
              tabIndex={-1}
              sx={{ color: colors.disabledGray }}
            >
              <div style={{ textDecoration: 'underline' }}>
                อัพโหลดไฟล์ใหม่
              </div>
              <VisuallyHiddenInput
                ref={props.ref}
                onClick={(event) => {
                  (event.target as HTMLInputElement).value = '';
                }}
                type="file"
                accept="image/png, image/jpeg , image/jpg"
                onChange={(event: any) => { props.onUpload && props.onUpload(event) }}
              />
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
}
