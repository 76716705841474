interface Option {
   readonly bgColor?:string
   readonly iconColor?:string
}
    
export  default function  Sign(props: Option ) {
    return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1047_3084)">
            <rect width="32" height="32" rx="16" fill={props.bgColor ?? '#F7C77C'} />
            <path d="M14.125 23.3754C15.6083 22.7921 15.2833 21.1837 14.5333 20.1671C13.7917 19.1254 12.7667 18.4087 11.7333 17.7171C10.9959 17.2526 10.3376 16.673 9.78335 16.0004C9.55001 15.7254 9.07501 15.2171 9.55835 15.1171C10.05 15.0171 10.9 15.5004 11.3333 15.6837C12.0917 16.0004 12.8417 16.3671 13.5417 16.8004L14.3833 15.3837C13.0833 14.5254 11.4167 13.7671 9.86668 13.5421C8.98335 13.4087 8.05001 13.5921 7.75001 14.5504C7.48335 15.3754 7.90835 16.2087 8.39168 16.8587C9.53335 18.3837 11.3083 19.1171 12.6333 20.4337C12.9167 20.7087 13.2583 21.0337 13.425 21.4171C13.6 21.7837 13.5583 21.8087 13.1667 21.8087C12.1333 21.8087 10.8417 21.0004 10 20.4671L9.15835 21.8837C10.4333 22.6671 12.5667 23.8921 14.125 23.3754ZM23.3667 10.3754C23.55 10.1921 23.55 9.89206 23.3667 9.71706L22.2833 8.63373C22.1963 8.54922 22.0797 8.50195 21.9583 8.50195C21.837 8.50195 21.7204 8.54922 21.6333 8.63373L20.7833 9.48373L22.5167 11.2171M15.1667 15.1004V16.8337H16.9L22.025 11.7087L20.2917 9.97539L15.1667 15.1004Z" fill={props.iconColor ?? '#FFF5E6'} />
        </g>
        <defs>
            <clipPath id="clip0_1047_3084">
                <rect width="32" height="32" rx="16" fill="white" />
            </clipPath>
        </defs>
    </svg>

)
}