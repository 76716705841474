/** COMPONENT */
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

/** CONSTANTS */
import { colors } from "../../constants/colors";

/** STYLE */
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import HelperText from "../input/helperText";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faTimes } from "@fortawesome/free-solid-svg-icons";

const StyledPopper = styled(Popper)(() => ({
  "& .MuiAutocomplete-groupLabel": {
    color: colors.disabledLightGray,
    // opacity: '0.6',
    fontSize: 13,
  },
}));

const CustomAutocomplete = styled(Autocomplete)(() => ({
  ".MuiOutlinedInput-root": {
    backgroundColor: colors.white,
    height: 32,
    alignItems: "center !important",
    borderRadius: 8,
    ".MuiAutocomplete-endAdornment": {
      zIndex: 1,
    },
    ".MuiButtonBase-root svg": {
      fontSize: 20,
      path: {
        fill: colors.themeMainColor,
      },
    },
    "&.Mui-disabled": {
      backgroundColor: `${colors.black_12} !important`,
      ".MuiButtonBase-root svg": {
        path: {
          fill: colors.black_60,
        },
      },
    },
    "&.MuiInputBase-sizeSmall": {
      ".MuiAutocomplete-input": {
        paddingRight: "16px",
      },
    },
  },
  ".MuiInputLabel-root, .MuiInputLabel-root span": {
    fontSize: 16,
    color: `${colors.black_60} !important`,
    backgroundColor: "transparent !important",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: 3,
      top: "50%",
      zIndex: -1,
    },
    '&[data-shrink="true"]': {
      "&:before": {
        backgroundColor: colors.white,
      },
      span: {
        color: `${colors.statusInactiveColor} !important`,
      },
    },
  },
  ".MuiOutlinedInput-notchedOutline": {
    top: "-5px !important",
    borderColor: `${colors.black_12} !important`,
  },
  ".MuiOutlinedInput-input": {
    color: `${colors.textPrimary} !important`,
    // '-webkit-text-fill-color': 'unset !important',
    WebkitTextFillColor: "unset !important",
    fontSize: 14,
    zIndex: 1,
    "&.Mui-disabled": {
      // "-webkit-text-fill-color": "unset",
      WebkitTextFillColor: "unset",
      color: `${colors.black_60} !important`,
      "&::placeholder": {
        opacity: "0.5",
      },
    },
  },
  ".MuiAutocomplete-clearIndicator": {
    backgroundColor: "transparent !important",
    svg: {
      fontSize: 20,
    },
    path: {
      fill: `${colors.black_60} !important`,
    },
  },
  "span.MuiAutocomplete-tag": {
    height: 23,
    minWidth: 36,
    backgroundColor: colors.disabledGray,
    borderRadius: 100,
    color: colors.white,
    textAlign: "center",
  },
  "&.Mui-enabled-border": {
    ".MuiOutlinedInput-root": {
      "&.Mui-focused": {
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: `${colors.themeMainColor} !important`,
        },
      },
    },
    ".MuiInputLabel-root, .MuiInputLabel-root span": {
      '&[data-shrink="true"]': {
        "&.Mui-focused": {
          color: `${colors.themeMainColor} !important`,
        },
      },
    },
  },
  "&.Mui-error": {
    "&.Mui-enabled-border, &.Mui-disabled-border, .MuiOutlinedInput-root.Mui-focused":
    {
      ".MuiOutlinedInput-notchedOutline": {
        border: `2px solid ${colors.statusInactiveColor} !important`,
      },
      '.MuiInputLabel-root[data-shrink="true"], .MuiInputLabel-root[data-shrink="true"] span':
      {
        color: `${colors.statusInactiveColor} !important`,
      },
    },
  },
  "&.Mui-active": {
    "&.Mui-enabled-border, &.Mui-disabled-border, .MuiOutlinedInput-root.Mui-focused":
    {
      ".MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${colors.themeMainColor} !important`,
        backgroundColor: colors.pink_06,
      },
    },
  },
  ".MuiInputBase-inputMultiline": {
    height: "90% !important",
  },
  "&.Mui-multiple": {
    ".MuiOutlinedInput-root": {
      paddingTop: "10px !important",
    },
    ".MuiAutocomplete-clearIndicator": {
      marginRight: "0.375rem",
    },
  },
}));

type SelectSearch = {
  labelId: string;
  inputLabel?: string;
  options: any;
  size?: "medium" | "small";
  defaultValue?: any;
  noOptionsText?: string;
  disableClearable?: boolean;
  popupIcon?: any;
  clearIcon?: any;
  open?: boolean;
  disabled?: boolean;
  groupBy?: any;
  renderOption?: (props: any, option: any) => any;
  renderTags?: (props: any, option: any) => any;
  getOptionLabel?: (option: any) => any;
  filterOptions?: (option: any) => any;
  onChange?: (event: any, value: any, reason: string) => void;
  onInputChange?: (event: any, value: any) => void;
  onOpen?: (event: any) => void;
  onClose?: (event: any, reason: any) => void;
  onBlur?: (event: any) => void;
  onClick?: (event: any) => void;
  value?: any;
  disabledBorder?: boolean;
  required?: boolean;
  helperText?: string;
  multiple?: boolean;
  multiline?: boolean;
  freeSolo?: boolean;
  height?: number | string;
  limitTags?: number;
  classesOption?: string;
  placeholder?: string;
  inputValue?: any;
  isOptionEqualToValue?: any;
  inputProps?: any;
  loading?: boolean;
  loadingText?: any;
  heading?: string;
  headingOther?: string;
  headingAlign?: string;
  headingColor?: string;
};

export default function AutocompleteSelect(props: SelectSearch) {
  return (
    <>
      {props.heading && (
        <div className="" style={{ textAlign: "left" || props.headingAlign }}>
          {props.heading}{" "}
          {props.required && <span style={{ color: colors.red }}>*</span>}{" "}
          {props.headingOther && (
            <span style={{ color: colors.red }}>{props.headingOther}</span>
          )}
        </div>
      )}
      <CustomAutocomplete
        PopperComponent={StyledPopper}
        id={props.labelId}
        open={props.open}
        options={props.options}
        groupBy={props.groupBy}
        renderOption={props.renderOption}
        renderTags={props.renderTags}
        getOptionLabel={props.getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            type="text"
            inputProps={{
              ...params.inputProps,
              inputMode: "none",
            }}
            label={props.inputLabel}
            placeholder={props.placeholder}
            required={props.required}
            multiline={props.multiline}
          />
        )}
        onChange={props.onChange}
        onClick={props.onClick}
        onInputChange={props.onInputChange}
        onOpen={props.onOpen}
        onClose={props.onClose}
        onBlur={props.onBlur}
        limitTags={props.limitTags}
        size={props.size || "small"}
        popupIcon={props.popupIcon || <FontAwesomeIcon icon={faCaretDown} />}
        clearIcon={props.clearIcon || <FontAwesomeIcon icon={faTimes} />}
        classes={{
          popper: `style-filter-select-search-wrap ${props.classesOption}`,
          paper: "style-filter-select-search",
          listbox: props.multiple
            ? "Mui-multiple custom-scroll"
            : "custom-scroll",
        }}
        sx={{
          ".MuiOutlinedInput-root": {
            paddingTop: props.multiple ? "10px !important" : "",
            minHeight: props.multiple ? props.height : "unset",
            height: props.multiple ? "auto" : props.height ? props.height : 40,
            alignItems: props.multiple
              ? "start !important"
              : "center  !important",
          },
        }}
        className={`${props.disabledBorder ? "Mui-disabled-border" : "Mui-enabled-border"
          } ${props.helperText ? "Mui-error" : ""} ${props.multiple ? "Mui-multiple" : ""
          } ${props.value ? "Mui-active" : ""}`}
        filterOptions={createFilterOptions({
          stringify: props.filterOptions,
        })}
        noOptionsText={props.noOptionsText}
        defaultValue={props.defaultValue}
        disableClearable={props.disableClearable}
        inputValue={props.inputValue}
        value={props.value}
        disabled={props.disabled}
        multiple={props.multiple}
        freeSolo={props.freeSolo}
        isOptionEqualToValue={props.isOptionEqualToValue}
        loading={props.loading}
        loadingText={"กำลังค้าหา"}
        blurOnSelect
      />
      {props.helperText && <HelperText label={props.helperText} />}
    </>
  );
}
