import BaseAPI from '../api-upload'

const path = '/payment/app/receipts'

export default class PaymentApi extends BaseAPI {


    static getReceiptHistory(payload: any): Promise<any> {
        return this.api.get(`${path}/receipt-all`, { params: payload }).then((res) => res)
        // console.log(payload);
        //return this.api.get(`http://localhost:3001/app/receipts/receipt-all`, { params: payload }).then((res) => res)
    }

    static getReceiptPDF(payload: any): Promise<any> {
        return this.api.post(`${path}/receipt-no`, payload).then((res) => res)
        //return this.api.post(`http://localhost:3001/app/receipts/receipt-no`, payload).then((res) => res)
    }

}