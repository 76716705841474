import { styled } from "@mui/material";
import _ from "lodash";
import { colors } from "../../../constants/colors";
import { onImgError } from "../../../constants/images";
import { numberFormat } from "../../../utils/common";

const Container = styled("div")(() => ({
  width: "100%",
  height: "100%",
  border: `1px solid ${colors.lightGray}`,
  borderRadius: 8,
  alignItems: "center",
  // padding: 8,
  // display: 'inline-grid'
}));

const ImageStyle = styled(`img`)(({ theme }) => ({
  width: 180,
  height: 180,

  [theme.breakpoints.down("lg")]: {
    width: 120,
    height: 120,
  },
}));

const Dot = styled("div")(({ theme }) => ({
  height: 10,
  width: 10,
  borderRadius: "50%",
  display: "inline-block",
  boxShadow: "0 0px 3px rgba(0, 0, 0, 0.5)",
}));

interface cardProdct {
  img?: string;
  name?: string;
  storage?: string;
  color?: any;
  price?: string;
  down?: string;
  onClick?: () => void;
}

export default function CardProduct(props: cardProdct) {
  return (
    <>
      <Container onClick={() => { props.onClick && props.onClick() }}>
        <div className="text-center" style={{ backgroundColor: colors.lightGray }}>
          <ImageStyle src={props?.img} onError={onImgError} />
        </div>
        <div style={{ padding: 8 }}>
          <div className="pt-2 fw-bold font-18">{props?.name || ""}</div>
          <div className="font-10 pt-1 d-flex flex-wrap" style={{ color: colors.gray, gap: 4 }}>
            {_.map(props.storage, (e: any, index: number) => {
              return (
                <div className="text-center" style={{ padding: 3, border: `1px solid ${colors.extraLightGray}`, borderRadius: 6, backgroundColor: colors.extraLightGray }}>
                  {e}
                </div>
              )
            })}
          </div>
          <div className="d-flex pt-1" style={{ border: "none" }}>
            {_.map(props.color, (e: any, index: number) => {
              return (
                <div key={index} style={{ paddingRight: 4 }}>
                  <Dot sx={{ backgroundColor: e.colorCode || "" }} />
                </div>
              )
            })}
          </div>
          <div>
            <div className="d-flex justify-content-start">
              <div style={{ paddingRight: 2 }}>
                <span className="font-12" >
                  {"เริ่มต้นที่"}
                </span>
              </div>
              <div className="ml-2">
                <span className="pl-2 font-12">
                  {`${numberFormat(props?.price, 0, 0)} บาท` || "0 บาท"}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-start" style={{ borderLeftColor: `1px solid ${colors.green}` }} >
              <div style={{ paddingRight: 2 }} >
                <span className=" font-12 font-bold">
                  {"ดาวน์เริ่มต้น"}
                </span>
              </div>
              <div className=" ml-2" style={{ color: colors.themeMainColor }}>
                <span className="pl-4 fw-bold font-12">
                  {`${numberFormat(props?.down, 0, 0)} บาท` || "0 บาท"}
                </span>
              </div>
            </div>
          </div>
        </div>

      </Container>
    </>
  );
}
