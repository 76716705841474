import { defaultMenu } from "../constants/menu";
import Login from "../pages/login/login";
import RegisterCustomer from "../pages/register-aomdown/register-customer";
import Register from "../pages/register/register";
import AomdownDetail from "../pages/register-aomdown/aomdown-detail/aomdown-detail";
import { routeName } from "./routers-name";
import Logout from "../pages/logout/logout";
import PersonalInfomation from "../pages/personal-infomation/personal-infomation";
import PaymentPage from "../pages/upload-slip/payment";

const routes = [
  // {
  //   key: defaultMenu.REGISTER.key,
  //   name: defaultMenu.REGISTER.name,
  //   textName: defaultMenu.REGISTER.textName,
  //   component: Register,
  //   path: routeName.register,
  //   header: false,
  //   footer: false,
  // },
  {
    key: defaultMenu.REGISTER_AOM_DOWN.key,
    name: defaultMenu.REGISTER_AOM_DOWN.name,
    textName: defaultMenu.REGISTER_AOM_DOWN.textName,
    component: RegisterCustomer,
    path: routeName.registerAomDown,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.AOM_DOWN_DETAIL.key,
    name: defaultMenu.AOM_DOWN_DETAIL.name,
    textName: defaultMenu.AOM_DOWN_DETAIL.textName,
    component: AomdownDetail,
    path: routeName.aomDownDetail,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.UPLOAD_SLIP.key,
    name: defaultMenu.UPLOAD_SLIP.name,
    textName: defaultMenu.UPLOAD_SLIP.textName,
    component: PaymentPage,
    path: routeName.paySlip,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.LOGIN.key,
    name: defaultMenu.LOGIN.name,
    textName: defaultMenu.LOGIN.textName,
    component: Login,
    path: routeName.login,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.LOGOUT.key,
    name: defaultMenu.LOGOUT.name,
    textName: defaultMenu.LOGOUT.textName,
    component: Logout,
    path: routeName.logout,
    header: false,
    footer: false,
  },
  {
    key: defaultMenu.PERSONAL_INFORMATION.key,
    name: defaultMenu.PERSONAL_INFORMATION.name,
    textName: defaultMenu.PERSONAL_INFORMATION.textName,
    component: PersonalInfomation,
    path: routeName.personalInfomation,
    header: false,
    footer: false,
  },
];

export default routes;
